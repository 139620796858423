import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/button";
import HomeLayout from '../components/homeLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomeLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Home" keywords={[`blog`, `gatsby`, `javascript`, `react`]} mdxType="SEO" />
    <p>{`Allegra H. Fullerton is a full-time researcher at the Center for Policy and Democracy at the University of Colorado Denver School of Public Affairs, where she leads several research projects. Her research is centered around emotions and politics, marginalized communities, policy theories, and network methods. She has published in Policy Studies Journal, Review of Policy Research, International Review of Public Policy, and more on topics spanning emotions, communities’ resilience when facing marginalizing policies, conflicts surrounding healthcare, power, and policy learning. She is an award-winning lecturer at the School of Public Affairs, where she teaches courses on policy processes and democracy, as well as negotiation. She sits on the organizing committee for the Conference on Policy Process Research, a community dedicated to advancing policy process theories and methods internationally. She also serves as the Digital Associate Editor at Policy and Politics. She received her MPA from Arizona State University and will complete her PhD in Public Affairs at the University of Colorado Denver in winter 2025. `}</p>
    <Link to="/resume/" mdxType="Link">
  <Button marginTop="35px" mdxType="Button">CV</Button>
    </Link>
    <div style={{
      visibility: 'hidden'
    }}>
      <Link to="/blog/" mdxType="Link">
  <Button marginTop="35px" mdxType="Button">Go to Blog</Button>
      </Link>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      